import React, { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import Base from "../Base"
import "../styles/contact.scss"

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMsg] = useState("")
  const [sending, setSending] = useState(false)

  const submit = (e) => {
    setSending(true)
    e.preventDefault()
    fetch("/form.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === "OK") {
          alert("Message sent")
        } else {
          alert(response.message)
        }
      })
      .finally(() => setSending(false))
  }

  return (
    <Base title="Contact">
      <div className="pure-g contact-section">
        <div className="pure-u-md-2-5 contact-msg ">
          <h1 className="text-center-md">
            <b>Send us a message</b>
          </h1>
          <h2 className="text-center-md">
            For general enquiries, media contact or investor information, please
            complete the email form.
          </h2>
        </div>
        <div className="pure-u-md-3-5 contact-window">
          <div className="contact-window-outline">
            <form className="pure-form pure-form-stacked" onSubmit={submit}>
              <input
                type="text"
                name="nameInput"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                name="emailInput"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <textarea
                type="text"
                name="messageInput"
                placeholder="Your message"
                onChange={(e) => setMsg(e.target.value)}
                required
              />
              <button type="submit" disabled={sending} className="sendBtn">
                <ClipLoader color="#fff" loading={sending} size={10} />
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="job-msg">
        <h1 className="text-center">
          <b>We&rsquo;re hiring</b>
        </h1>
        <h2 className="text-center">
          We&apos;re looking for Quant Researchers and Developers. Please email
          us at <a href="mailto:">jobs@pine-financial.com</a> for more
          information.
        </h2>
      </div>
    </Base>
  )
}

export default Contact
